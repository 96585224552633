import React,{ useState,useEffect} from "react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/20/solid";
export default function Notification({ type, message }) {

  let notificationMessage;
  
  // if(isVisible){
  if (type == "success") {
    notificationMessage = (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">{message}</p>
          </div>
        </div>
      </div>
    );
  } else if (type == "error") {
    notificationMessage = (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{message}</h3>
          </div>
        </div>
      </div>
    );
  } else if (type == "warning") {
    notificationMessage = (
      <div className="rounded-md bg-yellow-50 p-4 ">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">{message}</h3>
          </div>
        </div>
      </div>
    );
  } else if (type == "info") {
    notificationMessage = (
      <div className="rounded-md bg-blue-50 p-4 ">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm font-medium text-blue-800">{message}</p>
          </div>
        </div>
      </div>
    );
  }
// }
  return (
   <div className="fixed z-[1000] left-0 right-0 mx-auto max-w-[300px] top-[20px]">
    {notificationMessage}
    
    
    </div>
      )
}

// Function to show notification
export const showNotification = (setNotification, type, message) => {
  setNotification({ type, message });
  
  setTimeout(() => {
    setNotification(null);
  }, 3000);
};
