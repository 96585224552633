import React from 'react';
import { Navigate } from 'react-router-dom'; 

const withAuth = (ProtectedComponent) => (props) => {
  const userEmail = localStorage.getItem('userEmail');
  console.log('withAuth - userEmail:', userEmail); // Log for debugging

  if (!userEmail) {
    console.log('withAuth - Redirecting to login'); // Log for debugging
    return <Navigate to="/" replace />;
  }

  return <ProtectedComponent {...props} />;
};


export default withAuth;
