import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import UtilService from "../../services/utilService";
import { Helmet } from 'react-helmet';
import {
  BellIcon,
} from "@heroicons/react/24/outline";
import {
  PlusIcon,
} from "@heroicons/react/20/solid";

import TaskPLusLogo from "../../assets/images/TaskPlusLogo.png";

import CreateTask from "../task/createTask";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header() {
  const userName = localStorage.getItem('userName');
  const [open2, setOpen2] = useState(false);
  const [ userLogo, setUserLogo ] = useState('');

  // ... component logic
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    setUserLogo(UtilService.convertToAbbreviation(userName))
  }, [userName]);


  useEffect(() => {
    const loggedinEmail = localStorage.getItem('userEmail');
    if (!loggedinEmail) {
      navigate('/'); 
    }
  }, []);

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Task Plus</title>
        <script>
          {`
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "nwrsd9wi4y");
          `}
        </script>
      </Helmet>
    <div className="fixed left-0 right-0 top-0 z-[10]">
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className=" px-[10px]">
              <div className="flex h-[50px] justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="w-[75%]"
                      src={TaskPLusLogo}
                      alt="Your Company"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                    <button
                      onClick={() => setOpen2(true)}
                      type="button"
                      className="relative flex text-center items-center gap-x-1 rounded-md bg-atom_rojored px-3 h-[33px] text-sm font-semibold text-white shadow-sm hover:bg-atom_rojored focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored"
                    >
                     
                      <PlusIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                       <span>Create</span>
                    </button>
                  <div className="md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                      <div>
                        <Menu.Button className="relative flex items-center justify-center rounded-full bg-[#3C3C3C] text-white text-xs w-[30px] h-[30px] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          { userLogo }
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                          <Menu.Item>
                            {({ active }) => (
                              <button type="button"
                                onClick={logout}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              </div>
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="flex items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      Tom Cook
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      tom@example.com
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    onClick={logout}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen2}>
        {/* <div className="fixed inset-0" /> */}
        
        <CreateTask open={open2} setOpen={setOpen2}/>

      </Dialog>
      </Transition.Root>
     
    </div>
    </>
  );
}

export default Header;
