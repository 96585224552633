// useDefaultTab.js
import { useState, useEffect } from 'react';
import useAuthToken from '../services/api';

const useDefaultTab = (userId) => {
  const [defaultTab, setDefaultTab] = useState(null);
  const { callApiWithToken, callApi } = useAuthToken();

  useEffect(() => {
    const fetchDefaultTab = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}user-settings/${userId}`);
        if (response && response.ok) {
          const data = await response.json();
          setDefaultTab(parseInt(data.defaultTab));
        } else {
          setDefaultTab(0);
        }
      } catch (error) {
        // Handle fetch/network errors
        setDefaultTab(0);
        console.error("Error fetching default tab:", error);
      }
    };
  
    if (userId) {
      fetchDefaultTab();
    } else {
      setDefaultTab(null);
    }
  
    // Cleanup function (if needed)
    return () => {
      // Cleanup code (if needed)
    };
  }, [userId]);
  
  return defaultTab;
};

export default useDefaultTab;
