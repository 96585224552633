import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../App";

const useAuthToken = () => {
    let token;
    const emailId = localStorage.getItem("userEmail");
    let interactionInProgress = false;

    const fetchLatestToken = async () => {
        let scopes = ["user.read", "people.read", "User.ReadBasic.All", "offline_access"];
        try {
            let token = localStorage.getItem("token");
            let tokenExpiration = localStorage.getItem("tokenExpiration");
            
            if (token) {
                let expirationTime = tokenExpiration ? new Date(tokenExpiration) : null;
                
                // Check if the token is expired or close to expiration (within 5 minutes)
                if (!expirationTime || ( expirationTime && (expirationTime < new Date(Date.now() + 5 * 60 * 1000)))) {
                    let accountStr = localStorage.getItem("account");
                    let account = msalInstance.getActiveAccount() ? msalInstance.getActiveAccount() : JSON.parse(accountStr);
                    if (!account) {
                        throw new Error("No active account! Verify a user has been signed in.");
                    }
                    
                    const silentRequest = {
                        scopes: scopes,
                        account: account
                    };
                    
                    if (!interactionInProgress) {
                        interactionInProgress = true;
                        const loginResponse = await msalInstance.acquireTokenSilent(silentRequest);
                        token = loginResponse.accessToken;
                        expirationTime = loginResponse.expiresOn;
                        localStorage.setItem("token", token);
                        localStorage.setItem("tokenExpiration", expirationTime);
                        interactionInProgress = false;
                    } else {
                        console.log("Interaction is currently in progress. Waiting for completion...");
                    }
                }
            } else {
                console.log("Token or its expiration time not found. Fetching new token...");
                const loginResponse = await msalInstance.acquireTokenPopup({ scopes: scopes });
                token = loginResponse.accessToken;
                localStorage.setItem("token", token);
                localStorage.setItem("tokenExpiration", loginResponse.expiresOn);
            }
            
            return token;
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                // Fallback to interactive login if silent acquisition fails
                console.log("Silent acquisition failed, falling back to interactive login");
                try {
                    const loginResponse = await msalInstance.acquireTokenPopup({ scopes: scopes });
                    const newToken = loginResponse.accessToken;
                    localStorage.setItem("token", newToken);
                    localStorage.setItem("tokenExpiration", loginResponse.expiresOn);
                    return newToken;
                } catch (interactiveErr) {
                    console.log('Interactive login failed:', interactiveErr);
                }
            } else {
                console.log('Error:', err);
                interactionInProgress = false;
            }
        }
    };

    const callApi = async (endpoint, options = {}) => {
        try {
            const fetchOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    'Content-Type': 'application/json',
                    emailId: emailId.toLowerCase()
                }
            };
            const apiResponse = await fetch(endpoint, fetchOptions);

            if (!apiResponse.ok) {
                throw new Error(`API call failed with status ${apiResponse.status}`);
            }

            return await apiResponse.json();
        } catch (error) {
            console.error("API call failed", error);
            throw error;
        }
    };

    const callApiWithToken = async (endpoint, options = {}) => {
        try {
            let latestToken = await fetchLatestToken();
            const fetchOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    Authorization: `Bearer ${latestToken}`,
                    'Content-Type': 'application/json',
                    emailId: emailId.toLowerCase()
                }
            };
            const apiResponse = await fetch(endpoint, fetchOptions);

            if (!apiResponse.ok) {
                throw new Error(`API call failed with status ${apiResponse.status}`);
            }

            return await apiResponse.json();
        } catch (error) {
            console.error("Token acquisition or API call failed", error);
            throw error;
        }
    };

    const callApiWithTokenOnly = async (endpoint, options = {}) => {
        try {
            let latestToken = await fetchLatestToken();
            const fetchOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    Authorization: `Bearer ${latestToken}`,
                    'Content-Type': 'application/json',
                }
            };
            const apiResponse = await fetch(endpoint, fetchOptions);

            if (!apiResponse.ok) {
                throw new Error(`API call failed with status ${apiResponse.status}`);
            }

            return await apiResponse.json();
        } catch (error) {
            console.error("Token acquisition or API call failed", error);
            throw error;
        }
    };

    return { callApiWithToken, callApiWithTokenOnly, callApi };

}

export default useAuthToken;
