import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/login/login'; 
import Task from './components/task/task';
import Header from './components/header/header';
import { PublicClientApplication } from "@azure/msal-browser";
import withAuth from './common/withAuth';

// Initialize MSAL configuration
const config = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  scopes: ["user.read", "people.read", "User.ReadBasic.All", "offline_access"]
};

// Initialize MSAL instance
let msalInstance;

async function initMsal() {
  msalInstance = new PublicClientApplication(config);
  try {
    await msalInstance.initialize();
  } catch (error) {
    console.error("MSAL initialization error:", error);
  }
}

// Call the initialization function
initMsal();

// Export the MSAL instance for use in other components
export { msalInstance };

function App() {
  return (
    <>

    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        {/* Wrap protected routes with withAuth HOC */}
        <Route
          path="/tasks"
          element={
            // <withAuth>
              <>
                <Header />
                <Task />
              </>
            // </withAuth>
          }
        />
      </Routes>
    </Router>
    </>
  );
}

export default App;
