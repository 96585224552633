import './login.scss'
import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/microsoft-fill.png'
import { msalInstance } from '../../App';


function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedinEmail = localStorage.getItem('userEmail');
    if (loggedinEmail) {
      navigate('/tasks'); 
    } else {
      localStorage.clear(); // Clear localStorage if not logged in
    }
  }, []);

  const microsoftSignIn = async () => {
      try {
        const loginResponse = await msalInstance.loginPopup();
        console.log('response', loginResponse);
        if(loginResponse['accessToken']) {
          let userName = loginResponse['idTokenClaims']['name'];
          let userEmail = loginResponse['idTokenClaims']['preferred_username'];
          localStorage.setItem("userName", userName);
          localStorage.setItem("userEmail", userEmail);
          localStorage.setItem("token", loginResponse['accessToken']);
          localStorage.setItem("tokenExpiration", loginResponse['expiresOn']);
          const accounts = msalInstance.getAllAccounts();
          msalInstance.setActiveAccount(accounts[0]);
          localStorage.setItem("account", JSON.stringify(accounts[0]));
          navigate("/tasks");
        }
        // Access token is available in loginResponse.accessToken
      } catch (error) {
          console.log(error);
      }
  };

  return (
      <div className="srchMainImgWrap">
          {/* <img className="bgImg" src="../assets/images/searchEngineBg.jpg" /> */}
          <div className="searchEngineLoginWrap">  
              <span className="signInTxt">Sign In to </span>  
              <span className="companyNameTxt">Moglix Task Plus </span>  
              <button className="loginBtn"  onClick={microsoftSignIn}>  
                  <img className="windowIcon" src = {logo} />        
                  Login with Microsoft  
              </button>  
              <span className="disclaimer">This workspace allows you to sign in with your moglix email ID</span>
          </div>
      </div>

  );
}

export default Login;
  