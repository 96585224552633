import React from 'react';
import { usePagination, DOTS } from './usePagination';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/20/solid";
const Pagination = props => {
  const {
    classes,
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = (event) => {
    event.preventDefault();
    onPageChange(currentPage + 1);
  };

  const onPrevious = (event) => {
    event.preventDefault();
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (

    <div className={`${classes} items-center flex-col pl-[10px]`}>
        <div className='border-[#D7D7DC] border-l pl-2'>
          <div>
        <p className="text-[10px] text-[#6F6F6F] mb-1">
            Showing{" "}
            <span className="font-medium">
            {(currentPage - 1) * pageSize + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
            {Math.min(currentPage * pageSize, totalCount)}
            </span>{" "}
            of <span className="font-medium">{totalCount}</span> results
        </p>
        </div>
        <div>
        <nav
            className="isolate flex items-center"
            aria-label="Pagination"
        >
            <button type='button' className='h-[20px] w-[20px]'
            disabled={currentPage === 1}
            onClick={(event)=>onPrevious(event)}
            >
            <span
                className="relative inline-flex items-center rounded-l-md text-gray-400  cursor-pointer"
            >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            </button>
            {/* Current: "z-10 bg-atom_rojored text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atom_rojored", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            {/* Render page numbers */}
            {paginationRange.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                    return <button type='button' className="relative mx-1 inline-flex items-center justify-center rounded-sm border-box h-[20px] w-[20px] text-sm font-normal">&#8230; </button>;
                }
                return (
                    <button type='button'
                        key={i}
                        onClick={() => onPageChange(pageNumber)}
                        className={`relative mx-1 inline-flex items-center justify-center rounded-sm border-box h-[20px] w-[20px] text-sm font-normal cursor-pointer ${
                        pageNumber === currentPage
                            ? "bg-[#3C3C3C] text-white"
                            : "text-gray-900"
                        } `}
                    >
                        {pageNumber}
                    </button>
                    );

            })}
            <button type='button' className='h-[20px] w-[20px]'
            disabled={currentPage === lastPage}
            onClick={(event)=>onNext(event)}
            >
            <span
                className="relative inline-flex items-center rounded-l-md text-gray-400  cursor-pointer"
            >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            </button>
        </nav>
        </div>
    </div>
    </div>
  );
};
export default Pagination;
