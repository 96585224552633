import React, { createContext, useContext, useCallback, useMemo, useState, useEffect } from 'react';
import useDefaultTab from '../common/useDefaultTab';

const TaskEventContext = createContext();
const TabContext = createContext();

export const useTaskEvent = () => useContext(TaskEventContext);
export const useTabContext = () => useContext(TabContext);

export const TaskEventProvider = ({ children }) => {
    const listeners = useMemo(() => new Set(), []);

  const triggerFetchTasks = useCallback(() => {
    listeners.forEach((listener) => listener());
  }, [listeners]);

  const subscribe = useCallback((listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  }, [listeners]);

  return (
    <TaskEventContext.Provider value={{ triggerFetchTasks, subscribe }}>
      {children}
    </TaskEventContext.Provider>
  );

};

// TabProvider component
export const TabProvider = ({ children }) => {
    const loggedInUserId = localStorage.getItem("userEmail");
    const [selectedTab, setSelectedTab] = useState(null);
    const defaultTab = useDefaultTab(loggedInUserId);
    const params = new URLSearchParams(window.location.search);
    // console.log('defaultTab',defaultTab)

    // Function to get tab from URL query parameter
    const getTabFromQuery = () => {
      return params.get('tab');
    };

    // Set selectedTab based on defaultTab
    // useEffect(() => {
    //   const tabFromQuery = getTabFromQuery();
    //   if (tabFromQuery !== null) {
    //     setSelectedTab(parseInt(tabFromQuery));
    //   } else if (defaultTab !== null) {
    //     setSelectedTab(parseInt(defaultTab));
    //   } else {
    //     setSelectedTab(0);
    //   }
    // }, [defaultTab]);

   
    useEffect(() => {
      const tabFromQuery = getTabFromQuery();
    
      if (tabFromQuery !== null) {
        setSelectedTab(parseInt(tabFromQuery));
      } else if (loggedInUserId) {
        setSelectedTab(parseInt(defaultTab));
      } else {
        setSelectedTab(defaultTab !== null ? parseInt(defaultTab) : 0);
      }
    }, [defaultTab, loggedInUserId]);
    
    const [tabNames, setTabNames] = useState([
      { id: 0, name: 'Assigned to me', current: true, count: '0' },
      { id: 1, name: 'Raised by me', current: false, count: '0' },
    ]);

    return (
      <TabContext.Provider value={{ selectedTab, setSelectedTab, tabNames, setTabNames }}>
        {children}
      </TabContext.Provider>
    );
};
  
// Combined provider to use both TaskProvider and TabProvider
export const AppProvider = ({ children }) => (
    <TaskEventProvider>
        <TabProvider>{children}</TabProvider>
    </TaskEventProvider>
);
  
