import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition,TransitionChild,DialogPanel,DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Notification, {
  showNotification,
} from "../../common/notification/notification";
import Loader from "../../common/loader/loader";
import UtilService from "../../services/utilService";
import useAuthToken from "../../services/api";

export default function History({ open, setOpen,openHistoryMobile,setOpenHistoryMobile, taskId }) {
  const [notification, setNotification] = useState(null);
  const [showLoader, setshowLoader] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const { callApiWithToken } = useAuthToken();

  // method to fetch task history log
  const fetchTaskHistory = async () => {
    setshowLoader(true);
    const result = await callApiWithToken(
      `${process.env.REACT_APP_SERVER_URL}activity-log/task/${taskId}`
    );
    setshowLoader(false);
    if (result.success) {
      setTaskHistory(result.data);
    }
  };

  // method to fetch task details whenever selected task changes
  useEffect(() => {
    fetchTaskHistory();
  }, [taskId]);

  return (
    <>
      <Notification type={notification?.type} message={notification?.message} />
      {showLoader && (
        <div className="fixed right-0 h-screen bg-[#00000024] z-[1000] top-0 left-0 ">
          <div className="h-full w-full flex items-center justify-center">
            <Loader visible={showLoader} />
          </div>
        </div>
      )}
      {open &&
      <Transition show={open} appear={true}>
          <Dialog className="relative z-10" onClose={setOpen}>
            <TransitionChild
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#00000020] bg-opacity-75 transition-opacity" />


            </TransitionChild>
            <TransitionChild
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
            <div className="fixed inset-0 z-[999] flex overflow-hidden">
              <div className="pointer-events-none justify-end fixed w-[700px] bottom-0 top-0 right-0 flex sm:pl-16">
               
                  <DialogPanel transition className="pointer-events-auto w-full transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                    <div className="h-full overflow-y-scroll bg-white shadow-xl">
                      <div className="h-[53px] box-border  bg-white border-b border-[#C4C4C4]">
                        <div className="h-full flex items-center  px-4 justify-between space-x-3">
                          <div className="space-y-1">
                            <DialogTitle className="text-base font-semibold leading-6 text-[#3C3C3C]">
                              History - {taskId}
                            </DialogTitle>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-[#3C3C3C] hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="relative">
                        <div className="w-full history-table-height overflow-y-auto align-middle">
                          <table className="w-full ">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="w-[46%] py-3.5 pl-4 pr-3 text-left text-[12px] bg-[#f9f9fb] font-medium text-[#3C3C3C] sm:pl-3"
                                >
                                  Task
                                </th>
                                <th
                                  scope="col"
                                  className="w-[27%] px-3 py-3.5 text-left text-[12px] bg-[#f9f9fb] font-medium text-[#3C3C3C]"
                                >
                                  By
                                </th>
                                <th
                                  scope="col"
                                  className="w-[27%] px-3 py-3.5 text-left text-[12px] bg-[#f9f9fb] font-medium text-[#3C3C3C]"
                                >
                                  When
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {taskHistory.map((data, i) => (
                                <tr key={i} className="even:bg-gray-50">
                                  <td className="py-4 pl-4 pr-3 text-[12px] font-normal text-[#3C3C3C] sm:pl-3">
                                    {data?.field} {data?.action}
                                    <br />
                                    {(data.oldValue || data.newValue) && (
                                      <span>
                                        {data.oldValue == null ? 'NA':data.oldValue } --&gt; {data?.newValue}
                                        <br />
                                      </span>
                                    )}
                                    {data.additionalFields &&
                                      data.additionalFields.length > 0 && (
                                        <span>
                                          {data?.additionalFields?.[0]?.name}:{" "}
                                          {data?.additionalFields?.[0]?.value}
                                        </span>
                                      )}
                                  </td>
                                  <td className="px-3 py-4 text-[12px] font-normal text-[#3C3C3C]">
                                    {data.userId}
                                  </td>
                                  <td className="px-3 py-4 text-[12px] font-normal text-[#3C3C3C]">
                                    {UtilService.formatDateToDateTimeString(
                                      data.timestamp
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
              </div>
            </div>
            </TransitionChild>
          </Dialog>
      </Transition>
}
     {openHistoryMobile &&
       <Transition show={openHistoryMobile} as={Fragment}>
        <div className="hidden sm:block">
          <Dialog className="relative z-10" onClose={setOpenHistoryMobile}>
            <TransitionChild
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#00000030] bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0">
              <div className="pointer-events-none right-0 left-0 top-[120px] fixed bottom-0 flex justify-center">
                <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <DialogPanel className="pointer-events-auto w-full ">
                    <div className="flex flex-wrap flex-col bg-white overflow-hidden rounded-t-md">
                      <div className="h-[53px] box-border bg-white border-b border-[#C4C4C4]">
                        <div className="py-2 flex items-center px-4 justify-between">
                          <div className="space-y-1">
                            <DialogTitle className="text-base font-semibold leading-6 text-[#3C3C3C]">
                              History- {taskId}
                            </DialogTitle>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-[#3C3C3C] hover:text-gray-500"
                              onClick={() => setOpenHistoryMobile(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="relative flex-1">
                        <div className="w-full overflow-y-auto  h-[81vh] align-middle">
                          <div className="w-full">
                            <div className="bg-white rounded-t-md p-2.5 overflow-y-auto">
                              {taskHistory.map((data, i) => (
                                <div
                                  key={i}
                                  className="even:bg-gray-50 border border-[#C4C4C4] pb-1 mb-2.5"
                                >
                                  <div className="py-4 pl-4 pr-3 justify-between text-[12px] font-normal text-[#3C3C3C] flex ">
                                    <div
                                      scope="col"
                                      className="pr-3 w-[30%]  text-left text-[12px]  font-medium text-[#3C3C3C]"
                                    >
                                      Task
                                    </div>
                                    <div className="text-[12px] w-[70%]  font-normal text-[#3C3C3C]">
                                    {data?.field && data?.action &&(
                                      <div>
                                        {data?.field} {data?.action}
                                      </div>
                                    )}

                                      {(data.oldValue || data.newValue) && (
                                        <span>
                                          {data?.oldValue === null ? 'NA':data?.oldValue } --&gt; {data?.newValue}
                                        </span>
                                      )}
                                      {data.additionalFields &&
                                        data.additionalFields.length > 0 && (
                                          <span className="block">
                                            {data?.additionalFields?.[0]?.name}:
                                            {data?.additionalFields?.[0]?.value}
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                  <div className="py-2 pl-4 pr-3 justify-between text-[12px] font-normal text-[#3C3C3C] flex ">
                                    <div
                                      scope="col"
                                      className="pr-3 w-[30%]  text-left text-[12px]  font-medium text-[#3C3C3C]"
                                    >
                                      By
                                    </div>
                                    <div className="text-[12px] w-[70%]  font-normal text-[#3C3C3C]">
                                      {data.userId}
                                    </div>
                                  </div>
                                  <div className="py-2 pl-4 pr-3 justify-between text-[12px] font-normal text-[#3C3C3C] flex ">
                                    <div
                                      scope="col"
                                      className="pr-3 w-[30%]  text-left text-[12px]  font-medium text-[#3C3C3C]"
                                    >
                                      When
                                    </div>
                                    <div className="text-[12px] w-[70%]  font-normal text-[#3C3C3C]">
                                      {UtilService.formatDateToDateTimeString(
                                        data.timestamp
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </div>
      </Transition> 
    }
    </>
  );
}
