import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

export default function loader({visible}) {
  return (
    <RotatingLines
    visible={visible}
    height="96"
    width="96"
    color="#D9232D"
    strokeColor="#D9232D"
    strokeWidth="5"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
    wrapperStyle={{}}
    wrapperClass=""
    />
  )
}
