import { format, parseISO } from 'date-fns';
const UtilService = {
    convertToAbbreviation: (fullName) => {
        // Remove any commas from the full name
        fullName = fullName.replace(',', '');
        const words = fullName.split(' ');
        let abbreviation = '';
        // Ensure there are at least two words (first name and last name)
        if (words.length >= 2) {
            // Take the first letter of the last name
            abbreviation += words[words.length - 1].charAt(0);
            // Take the first letter of the first name
            abbreviation += words[0].charAt(0);
        } else {
            abbreviation += words[0].charAt(0);
        }
        return abbreviation.toUpperCase();
    },

    convertEmailToAbbreviation: (emailId) => {
        let parts = emailId.split('@');
        const name = parts[0].split('.');
        let abbreviation = '';
        // Ensure there are at least two words (first name and last name)
        if (name.length >= 2) {
            // Take the first letter of the last name
            abbreviation += name[name.length - 1].charAt(0);
            // Take the first letter of the first name
            abbreviation += name[0].charAt(0);
        } else {
            abbreviation += name[0].charAt(0);
        }
        return abbreviation.toUpperCase();
    },

    formatDate: (date) => {
        if (!date) return '';
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    },

    getDueDateText: (dueDateTimestamp) => {
        const currentDate = new Date();
        const due = new Date(dueDateTimestamp);
    
        // Calculate the difference in milliseconds
        const differenceMs = due - currentDate;
    
        // Calculate the difference in days
        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        if (differenceDays < 0) {
            // Due date is in the past
            return {text: `${Math.abs(differenceDays)} ${Math.abs(differenceDays) === 1 ? 'day' : 'days'} ago`, class: 'past'};
        } else if (differenceDays > 0) {
            // Due date is in the future
            return {text: `${differenceDays} ${differenceDays === 1 ? 'day' : 'days'} to go`, class: 'future' };
        } else {
            // Due date is today
            return {text: "Today", class: 'present'};
        }
    },
      
    // Utility function to convert MongoDB LocalDateTime to desired format
    formatDateToDateTimeString: (date) => {
        // Parse the ISO date string
        const parsedDate = parseISO(date);
        // Format the parsed date
        const formattedDate = format(parsedDate, "dd MMMM yyyy 'at' HH:mm");
        return formattedDate;
    },

    // Utility function to convert MongoDB LocalDateTime to desired format
    formatDateToDateString: (date) => {
        // Parse the ISO date string
        const parsedDate = parseISO(date);
        // Format the parsed date
        const formattedDate = format(parsedDate, "dd/MM/yyyy");
        return formattedDate;
    },

    formatTimestampTodate(timestamp) {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
    }
      
};
export default UtilService;


  
  