import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './contexts/TaskEventContext';
import ErrorBoundary from './ErrorBoundary';

function logErrorToMyService(error, errorInfo) {
  console.error("Logged error:", error, errorInfo);
  // Here you can send the error to your error reporting service
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </AppProvider>
);

window.addEventListener('error', (event) => {
  logErrorToMyService(event.error, { componentStack: event.filename });
  event.preventDefault();  // Prevent the default error overlay
});

window.addEventListener('unhandledrejection', (event) => {
  logErrorToMyService(event.reason, { componentStack: '' });
  event.preventDefault();  // Prevent the default error overlay
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
